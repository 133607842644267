<template>
	<!-- Edit details modal -->
	<nice-modal
		@close="cancel"
	>
		<div class="modal-wrapper">
			<aside
				v-if="creative[type]"
				class="preview"
			>
				<video
					v-show="isMediaLoaded"
					v-if="type === 'video' && mediaUrl"
					:src="mediaUrl"
					:type="creative[type].mime_type"
					class="media video"
					playsinline
					preload="auto"
					autoplay
					muted
					controls
					@loadedmetadata="isMediaLoaded = true"
				/>

				<img
					v-if="type === 'image' && mediaUrl"
					:src="mediaUrl"
					:type="creative[type].mime_type"
					class="media image"
				/>

				<template
					v-else-if="type === 'audio' && mediaUrl"
				>

					<audio
						v-show="isMediaLoaded"
						class="media audio"
						:src="mediaUrl"
						preload="auto"
						controls
						@loadedmetadata="isMediaLoaded = true"
					/>

					<nice-icon-2
						icon="melody-15"
						class="preview-icon"
					/>
				</template>
			</aside>

			<section class="modal">
				<header class="header">
					<nice-icon-2
						v-if="icon"
						class="header-icon"
						:icon="icon"
					/>
					<div class="header-wrapper">
						<h2 class="title">{{ title | capitalize }} <template v-if="name">{{ $t('common.for') }} <span class="creative-name">{{ name }}</span></template></h2>
					</div>
				</header>

				<div class="details cl-container cl-pad-no_sides">
					<nice-input
						v-if="editMode"
						v-model="name"
						class="cl-xs12 cl-pad"
						:label="$t('common.creative_name')"
						:placeholder="$t('common.creative_name_placeholder') | capitalize"
					/>

					<nice-input
						v-if="editMode"
						v-model="description"
						class="cl-xs12 cl-pad"
						:label="$t('common.description')"
						:placeholder="$t('common.description_placeholder') | capitalize"
					/>

					<div v-else class="detail cl-xs12 cl-pad" >
						<nice-label class="detail-label" :label="$t('common.description')" />
						<span class="value multiline" v-html="creative.description || '&mdash;'" />
					</div>

					<!-- resolution -->
					<div
						v-if="type === 'video' || type === 'image'"
						class="detail cl-xs6 cl-pad"
					>
						<nice-label
							class="detail-label"
							:error="resolutionIssue"
							:label="$t('campaign.video_resolution')"
						/>
						<span class="value" v-html="creativeResolution" />
					</div>

					<!-- duration -->
					<div
						v-if="type === 'video' || type === 'audio'"
						class="detail cl-xs6 cl-pad"
					>
						<nice-label
							class="detail-label"
							:error="durationIssue"
							:label="$t('campaign.video_duration')"
						/>
						<span class="value" v-html="formatDuration(creative[type].duration) || '&mdash;'" />
					</div>

					<!-- screen resolution -->
					<div
						v-if="type === 'video' || type === 'image'"
						class="detail cl-xs6 cl-pad"
					>
						<nice-label
							class="detail-label"
							:label="$t('campaign.screen_resolution')"
						/>
						<span class="value" v-html="creativeScreenResolution || '&mdash;'" />
					</div>

					<!-- ad duration -->
					<div class="detail cl-xs6 cl-pad" >
						<nice-label class="detail-label" :label="$t('campaign.ad_duration')" />
						<span class="value" v-html="formatDuration(context.instance.ad_duration) || '&mdash;'" />
					</div>


					<template
						v-if="showAuditStatus"
					>
						<div
						  v-for="audit in currentAudits"
							:key="`audit-${audit.id}`"
							class="audit-line cl-container cl-xs12"
						>

							<hr class="cl-xs12 cl-pad" />

							<!-- audit status -->
							<div class="cl-xs12 cl-pad" >
								<nice-label class="detail-label" :label="$t('campaign.current_status')" />

								<br />

								<NiceActionList
									v-if="isSSPAdminOrApprover"
									class="status-list"
									:status="audit ? audit.status : AUDIT_STATUS_CODES.PENDING_AUDIT"
									:list="auditActionList(audit)"
									icons-in-list
									hide-on-click

									@change="auditChangeHandler(audit, $event)"
								/>
								<div v-else class="status">
									<nice-icon-2
										:icon="currentStatus(audit).icon"
										:state="currentStatus(audit).iconState"
									/>
									<span>{{ $t('campaign.creative_audit_status_' + currentStatus(audit).label_translation_id) | capitalize }}</span>
								</div>
							</div>

							<!-- audit feedback -->
							<div class="cl-xs12 cl-pad" >
								<nice-label class="detail-label" :label="$t('campaign.audit_message')" />
								<span class="value multiline" >{{ currentFeedback(audit) || '—' }}</span>
							</div>

							<!-- who reviewed -->
							<div class="cl-container cl-xs12" >
								<div class="cl-xs6 cl-pad" >
									<nice-label class="detail-label" :label="$t('common.changed')" />
									<span class="value" >{{ audit.mtime ? formatDate(audit.mtime) : '—' }}</span>
								</div>

								<div class="cl-xs6 cl-pad" >
									<nice-label class="detail-label" :label="$t('common.changed_by')" />
									<span class="value" >{{ audit.modified_by ? audit.modified_by.username : '—' }}</span>
								</div>
							</div>

						</div>
					</template>

				</div>

				<footer class="footer">
					<nice-button-2
						v-if="!editMode"
						palette="gray"
						@click="close"
					>{{ $t('common.close') }}</nice-button-2>

					<nice-button-2
						v-if="editMode"
						palette="gray"
						@click="cancel"
					>{{ $t('common.cancel') }}</nice-button-2>

					<nice-button-2
						v-if="editMode"
						palette="gray"
						:disabled="virginity"
						@click="reset"
					>{{ $t('common.reset') }}</nice-button-2>

					<nice-button-2
						v-if="editMode"
						filled
						@click="apply"
					>{{ $t('common.apply') }}</nice-button-2>
				</footer>
			</section>
		</div>
	</nice-modal>
</template>


<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';

import NiceInput from '@/ui/nice-input';
import NiceModal from '@/ui/nice-modal';
import { formatDuration, formatFileSize } from '@/utilites';
import NiceActionList from '@/ui/nice-action-list';
import AUDIT_STATUS_CODES from '@/constants/audit-status-codes';

import AUDIT_STATUSES, { DEFAULT_CREATIVE_AUDIT } from './audit-statuses';
import { mixin } from './mixins/audit';


function formatDate(date) {
	return moment(date).format('DD MMM`YY');
}


export default {
	name: 'CreativeDetailsModal',


	mixins: [
		mixin,  // auditActionList
	],


	components: {
		NiceInput,
		NiceModal,
		NiceActionList,
	},


	props: {
		title: {
			type: String,
			default: '',
		},

		icon: {
			type: String,
			default: 'see-34',
		},

		creative: {
			type: Object,
			required: true,
		},

		context: {
			type: Object,
			required: true,
		},

		editMode: Boolean,
		showAuditStatus: Boolean,
	},


	data() {
		return {
			name: this.creative.name || '',
			description: this.creative.description || '',
			isMediaLoaded: false,

			AUDIT_STATUS_CODES: AUDIT_STATUS_CODES,
		};
	},


	computed: {
		...mapState('app', {
			appSide: 'side',
		}),

		...mapGetters('app', [
			'isSSPAdminOrApprover',
			'isSSPViewer',
			'isDSP',
		]),

		/**
		 * The type of a creative
		 */
		type() {
			return this.creative.type;
		},

		/**
		 * No changes
		 */
		virginity() {
			return this.name === this.creative.name
				&& this.description === this.creative.description;
		},

		/**
		 * Returns url of the creative media
		 */
		mediaUrl() {
			if (!this.creative[this.type] || !this.creative[this.type].file) {
				return null;
			}

			const media = this.creative[this.type].file;

			if (typeof media === 'string') {
				return media;
			}

			if (media instanceof File) {
				return window.URL.createObjectURL(media);
			}

			return null;
		},

		creativeResolution() {
			const creative = this.creative[this.type];
			return creative.resolution || '&mdash;';
		},

		creativeScreenResolution() {
			const creative = this.creative[this.type];
			return creative.screen_resolution || '&mdash;';
		},

		/**
		 * Audits
		 * Returns audits for current campaign/request
		 *
		 * @return {Array} audits
		 */
		currentAudits() {
			const id = this.context.instance.id;

			if (
				!this.creative.audits
				|| this.creative.audits.length == 0
				|| typeof id !== 'number'
			) {
				return [DEFAULT_CREATIVE_AUDIT];
			}

			return this.creative.audits.filter(a => a.campaign === id);
		},


		// resolution mismatch issue
		resolutionIssue() {
			if (this.creative.type !== 'video' && this.creative.type !== 'image') {
				return false;
			}

			if (this.creativeResolution === this.creativeScreenResolution) {
				return false;
			}

			return this.$t('errors.creative_reslution_mismatch', {
				current: this.creativeResolution,
				target: this.creativeScreenResolution,
			});
		},

		// duration missmstch issue
		durationIssue() {
			if (this.context.instance.ad_duration === Math.round(this.creative[this.creative.type].duration)) {
				return false;
			}

			return this.$t('errors.creative_duration_mismatch', {
				current: this.formatDuration(this.creative[this.creative.type].duration),
				target: this.formatDuration(this.context.instance.ad_duration),
			});
		},
	},

	methods: {
		formatDate,
		formatDuration,
		formatFileSize,

		/**
		 * Apply changes and close the modal
		 */
		apply() {
			this.$emit('apply', {
				name: this.name,
				description: this.description,
			});
			this.close();
		},

		/**
		 * Reset changes
		 */
		reset() {
			this.name = this.creative.name;
			this.description = this.creative.description;
		},

		/**
		 * Cancel changes and close the modal
		 */
		cancel() {
			this.$emit('cancel');
			this.close();
		},

		/**
		 * Close the modal
		 */
		close() {
			this.$emit('close');
		},

		auditChangeHandler(audit, status) {
			this.$emit('audit-status-change', {
				audit,
				status
			});
		},

		currentFeedback(audit) {
			if (!(audit && audit.feedback && audit.feedback.length)) {
				return null;
			}

			return audit.feedback[audit.feedback.length - 1];
		},

		currentStatus(audit) {
			if (!audit) {
				return null;
			}

			return AUDIT_STATUSES[audit.status];
		},
	},
};
</script>


<style lang="sass" scoped>
.modal-wrapper
	display: flex
	flex-direction: row
	// justify-content: stretch
	align-items: stretch
	min-height: 500px
	height: 675px
	width: 1000px
	max-height: calc(100vh - 160px)

.preview
	position: relative
	display: block
	background-color: #000000
	flex-basis: 600px

	> video.media
		width: 100%
		height: 100%
		object-fit: contain

	> img.media
		width: 100%
		height: 100%
		object-fit: contain

	> audio.media
		position: absolute
		display: block

		bottom: 20px
		left: 40px
		right: 40px
		width: calc(100% - 80px)


.preview-icon
	position: absolute
	height: 128px
	width: 100%
	color: white
	opacity: .3
	top: 50%
	transform: translate3d(0, -50%, 0)
	--ni-icon-sign: currentColor

.modal
	display: flex
	flex-direction: column
	padding: 30px 40px
	height: 100%
	flex-basis: 360px
	box-sizing: border-box
	flex-grow: 1
	overflow: auto

.header
	display: flex
	align-items: flex-start
	align-content: flex-start
	justify-content: flex-start
	margin-bottom: 40px

.header-icon
	width: 34px
	flex-shrink: 0


.header-wrapper
	&:not(:first-child)
	margin-left: 15px

.title
	font-size: 16px
	line-height: 20px
	margin: calc((34px - 20px) / 2) 0
	font-weight: normal

.creative-name
	color: var(--text_2_color, currentColor)

.details
	align-content: flex-start
	margin-bottom: 30px

.detail
	display: flex
	flex-direction: column

.audit-line
	display: block
	// flex-direction: column

	.status-list
		display: inline-flex

.value
	display: flex
	align-items: center
	line-height: 30px

	white-space: nowrap
	text-overflow: ellipsis

	&.multiline
		white-space: unset

	& > .icon
		margin-right: 10px

.status
	display: flex
	color: $nice_color-darkgray
	font-size: $fsz__smaddle
	align-items: center

// .details-row
// 	width: 100%
// 	display: grid
// 	grid-template-columns: repeat(2, 1fr)
// 	grid-column-gap: 30px
// 	grid-row-gap: 20px

.footer
	display: flex
	margin-top: auto
	justify-content: flex-end

	& > :not(:last-child)
		margin-right: 35px
</style>
