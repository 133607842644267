import _ from 'underscore';

import apiCampaign from '@/api/campaign';
import { setterGenerator } from '@/utilites.js';
import AUDIT_STATUS_CODES from '@/constants/audit-status-codes';
import { storeFactory } from './_base';


const DEFAULT_PAGE_SIZE = 15;
const serializeCampaign = item => {
	let ctime = new Date(item.ctime);
	let mtime = new Date(item.mtime);

	return {
		...item,
		ctime: ctime,
		mtime: mtime,
	};
};

// TODO: OLD: use `constants/audit-status-codes`
export const STATUS_PLAYING = AUDIT_STATUS_CODES.APPROVED;
export const STATUS_PAUSED = AUDIT_STATUS_CODES.PAUSED;
export const STATUS_FINISHED = AUDIT_STATUS_CODES.FINISHED;
export const STATUS_AWAITING_APPROVAL = AUDIT_STATUS_CODES.PENDING_AUDIT;
export const STATUS_REJECTED = AUDIT_STATUS_CODES.DENIED;
export const STATUS_ARCHIVED = AUDIT_STATUS_CODES.ARCHIVED;
export const STATUS_DELETED = AUDIT_STATUS_CODES.DELETED;

const generated = storeFactory('campaign', () => apiCampaign, serializeCampaign);


export default {
	namespaced: true,

	state: {
		list: [],
		query: null,
		fieldsSensitive: null,
		totalCount: 0,
		page: 1,
		pageSize: DEFAULT_PAGE_SIZE,
		meta: null,
	},

	mutations: {
		...setterGenerator(['fieldsSensitive', 'totalCount', 'page', 'pageSize', 'meta',]),
		...generated.mutations,  // setList, changeItem

		/**
		 * save filters to store
		 * + сохраняет стек используемых в фильтрах полей
		 *
		 * @param {State}
		 * @param {query{}} page
		 */
		setQuery(state, query) {
			state.query = query;

			let fields = {};
			_.each(_.keys(state.query), (filter) => {
				// дефолтное значение, атрибут фильтра равен полю девайса
				let filterFields = [filter];

				switch(filter) {
				case 'search':
					filterFields = ['name'];
					break;

				case 'by_me':
					filterFields = ['created_by'];
					break;
				}

				_.each(filterFields, (field) => {

					if (_.has(fields, field)) {
						fields[field].push(filter);
					} else {
						fields[field] = [filter];
					}
				});
			});

			state.fieldsSensitive = Object.freeze(fields);
		},
	},

	getters: {
		...generated.getters,  // loadingKeyBase, loadingKeyList, loadingKeyItem, loadingKeyItemChange, isLoading, isLoadingList, isLoadingItem, isFilterChanged
	},

	actions: {
		...generated.actions,  // requestList?, getList, create, getItem, change, delete


		/**
		 * Change Request audit
		 *
		 * @param {int} id - campaign ID
		 * @param {int} sspAgencyId - auditing SSP agency ID
		 * @param {int} status - new status. see `constants/audit-status-codes.js`
		 * @param {object} config - axios request config
		 */
		async changeAudit({ rootState }, { id, sspAgencyId, status, config }) {
			return await apiCampaign.changeAudit(id, sspAgencyId, status, config);
		},

	},
};
