import _ from 'underscore';
import * as moment from 'moment';

import { requestStats } from '@/api/analytics';


export const RANGE_TYPE_TO_MOMENT_DIFF_MAP = {
	hourly: 'hours',
	daily: 'days',
	weekly: 'weeks',
	monthly: 'months',
};

export const TIMESTAMP_KEY_FORMAT = 'YYYY-MM-DD HH:mm';


/**
 * Generate labels for the stats graphs
 */
export function getStatsGraphLabels(rangeType, dateFrom, dateTill) {

	// cloning
	dateFrom = moment(dateFrom).set('minute', 0);
	dateTill = moment(dateTill).set('minute', 0);

	let diffType = RANGE_TYPE_TO_MOMENT_DIFF_MAP[rangeType];

	// we need partial intervals too
	let labelsNum = Math.ceil(dateTill.diff(dateFrom, diffType, true));

	if (rangeType == 'hourly') {
		labelsNum = 24;
	}

	let labels = [];

	for (let i = 0; i < labelsNum; i++) {
		let label = dateFrom.clone();
		label.add(i, diffType);
		labels.push(label);
	}

	return labels;
}


/**
 * Get stats graph data from the API
 */
export async function getStatsGraph(type, dateFrom, dateTill, platforms, agency, campaign) {
	// `format()` returns ISO 8601, no fractional seconds
	// https://momentjs.com/docs/#/displaying/format/
	let params = {
		'type': type,
		'date_from': moment(dateFrom).format(),
		'date_till': moment(dateTill).format(),
	};

	if (platforms && platforms.length > 0) {
		params['network_ids'] = platforms.join(',');
	}
	if (agency) {
		params['dsp_agency_id'] = agency;
	}
	if (campaign) {
		params['campaign_id'] = campaign;
	}

	let stats = await requestStats(params);

	let impressionsDict = {};
	_.each(stats['impressions'], function(imp) {
		let label = moment(imp.ts).utcOffset(imp.ts).format(TIMESTAMP_KEY_FORMAT);
		impressionsDict[label] = imp.impressions;
	});

	let spentIncomeDict = {};
	_.each(stats['spent_income'], function(imp) {
		let label = moment(imp.ts).utcOffset(imp.ts).format(TIMESTAMP_KEY_FORMAT);
		spentIncomeDict[label] = imp.money;
	});

	let adPlaysDict = {};
	_.each(stats['ad_plays'], function(imp) {
		let label = moment(imp.ts).utcOffset(imp.ts).format(TIMESTAMP_KEY_FORMAT);
		adPlaysDict[label] = imp.events;
	});

	return {
		meta: stats.meta,
		impressions: impressionsDict,
		spentIncome: spentIncomeDict,
		adPlays: adPlaysDict,
	};
}
