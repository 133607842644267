import {
	addDevicesToManualFilter,
	removeDevicesFromManualFilter,
} from '@/api/dsp/manual-inventory-filter';
import {
	DEVICE_BLACK_LIST,
	DEVICE_WHITE_LIST,
} from '@/constants/device';


/**
 * Create layout class for a device popup
 */
export function createDevicePopupLayoutClass(window, vm) {
	const DeviceBalloonContentLayout = window.ymaps.templateLayoutFactory.createClass(
		`<div class="ymap-device-popup">
			<span class="name">{{ properties.name }}</span>
			<span class="address">{{ properties.address }}</span>
			<span class="count-screen">{{ properties.count_screen_label }}: {{ properties.count_screen }}</span>
			{% if (!properties.without_control) %}
			<div class="control" >
				<button
					id="add-to-blacklist"
					class="nice-button {% if (properties.state == 'black') %}filled-red{% endif %} red"
				>{{ properties.add_to_blacklist_label }}</button>
				<button
					id="add-to-whitelist"
					class="nice-button {% if (properties.state == 'white') %}filled-green{% endif %}"
				>{{ properties.add_to_whitelist_label }}</button>
			</div>
			{% endif %}
		</div>`,
		{
			build: function () {
				DeviceBalloonContentLayout.superclass.build.call(this);

				this.addToBlacklistHandler = async () => await this.handleActionButtonClick(DEVICE_BLACK_LIST);
				this.addToWhitelistHandler = async () => await this.handleActionButtonClick(DEVICE_WHITE_LIST);

				window.document.getElementById('add-to-blacklist').addEventListener(
					'click',
					this.addToBlacklistHandler,
					false
				);
				window.document.getElementById('add-to-whitelist').addEventListener(
					'click',
					this.addToWhitelistHandler,
					false
				);
			},

			clear: function () {
				window.document.getElementById('add-to-blacklist').removeEventListener(
					'click',
					this.addToBlacklistHandler,
					false
				);
				window.document.getElementById('add-to-whitelist').removeEventListener(
					'click',
					this.addToWhitelistHandler,
					false
				);

				DeviceBalloonContentLayout.superclass.clear.call(this);
			},


			/**
			 * Handle add/remove black/white button click
			 */
			handleActionButtonClick: async function(type) {
				const device = this.getData().object;
				const deviceId = device.id;
				const currentType = device.properties.state;

				try {
					// remove rule on a repeating click
					if (type === currentType) {
						await removeDevicesFromManualFilter(
							vm.campaignId,
							[deviceId],
						);
					}
					// add rule
					else {
						await addDevicesToManualFilter(
							vm.campaignId,
							type,
							[deviceId],
						);
					}
				}
				catch (error) {
					const data = error.response && error.response.data;
					vm.$log.error(`Error ${error.response && error.response.status}. Failed rule change.`, data);
					throw error;
				}

				vm.$emit('update');
			},
		}
	);

	return DeviceBalloonContentLayout;
}
