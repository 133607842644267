<template>
	<section
		class="review-tab layout-center805"
	>
		<main class="wrapper">
			<div class="details cl-container cl-pad-no_sides">
				<!-- budget -->
				<div class="detail detail-huge cl-xs4 cl-pad" >
					<nice-label class="label" :label="$t('campaign.budget') | capitalize" />
					<span class="value">
						<nice-icon-2
							v-if="!isSSPViewer"
							icon="diagram"
							:state="(spentTotal > instance.budget ? instance.budget : spentTotal) / instance.budget * 100"
							class="value-icon"
						/>
						<span v-if="!isSSPViewer">{{ spentTotal | formatMoney('') }}</span>
						<span class="shaded">
							<span v-if="!isSSPViewer">/</span>
							{{ instance.budget | parseFloat }}
						</span>
						<span class="units">{{ instance.currency | formatCurrency }}</span>
					</span>
				</div>

				<!-- max bid -->
				<div class="detail detail-huge cl-xs4 cl-pad" >
					<template v-if="isBidEditModeOn && isDSP" >
						<div>
							<nice-input
								v-model="bidTempValue"
								:units="$options.filters.formatCurrency(instance.currency)"
								class="inline-edit-field inline-edit-field_huge"
								type="number"
								step="0.0001"
								:label="$t('campaign.max_bid') | capitalize"
							/>
							<nice-button-2
								class="inline-edit-button"
								icon="check"
								@click="applyBidChanges"
							/>
							<nice-button-2
								class="inline-edit-button"
								icon="error"
								@click="cancelBidChanges"
							/>
						</div>
					</template>
					<template v-else >
						<nice-label class="label" :label="$t('campaign.max_bid') | capitalize" />
						<span class="value">
							<span>{{ $options.filters.formatNumber(bidValue, decimal=2, separator='') || '—' }}</span>
							<span class="units">{{ instance.currency | formatCurrency }}</span>
							<nice-button-2
								v-if="isDSP"
								class="inline-edit-button"
								icon="pencil-13"
								@click="editBid"
							/>
						</span>
						<p
							class="input--description"
							v-html="$t('campaign.bid_desc_short', {
								min_price: $options.filters.formatCash(campaignStats.price.min, instance.currency, 2),
								max_price: $options.filters.formatCash(campaignStats.price.max, instance.currency, 2),
							})"
						/>
					</template>
				</div>

				<div class="button-wrapper cl-xs4 cl-pad">
					<nice-button-2
						class="download-button"
						icon="load-24"
						@click="exportExcel"
					>
						{{ $t('campaign.download_inventory_list') }}
					</nice-button-2>
					<nice-button-2
						v-if="!instance.is_request"
						class="download-button"
						icon="load-24"
						@click="downloadBroadcastingReport"
					>
						{{ $t('campaign.download_broadcasting_report') }}
					</nice-button-2>
				</div>

				<!-- points -->
				<div class="detail detail-huge cl-xs4 cl-pad" >
					<nice-label class="label" :label="$t('campaign.points') | capitalize" />
					<span class="value">
						<nice-icon-2
							icon="monitor-13"
							class="value-icon"
						/>
						<span>{{ campaignStats ? campaignStats.inventory.value : '—' }}</span>
					</span>
				</div>

				<!-- impressions -->
				<div class="detail detail-huge cl-xs4 cl-pad" >
					<nice-label class="label" :label="$t('campaign.impressions') | capitalize" />
					<span class="value">
						<span>{{ campaignStats ? impressionsTotal : '—' | parseFloat }}</span>
					</span>
				</div>

				<!-- ad plays -->
				<div class="detail detail-huge cl-xs4 cl-pad" >
					<nice-label class="label" :label="$t('campaign.ad_plays') | capitalize" />
					<span class="value">
						<span>{{ campaignStats ? adPlaysTotal : '—' | parseFloat }}</span>
						<!-- <span class="units">{{ currentPaymentModel.trading_units }}</span> -->
					</span>
				</div>
			</div>

			<hr class="line">

			<nice-stats
				:campaign="instance.id"
				:platforms="instance.platforms"
				:agency="instance.owner"

				:graphData="graphData"
				:labelData="labelData"

				:impressions="impressions"
				:selectorLabels="statLabels"
				:spent="spent"
				:adPlays="adPlays"

				:formatHint="formatHint"

				:maxBidHistory="maxBidHistory"

				@range-updated="rangeUpdated"
				@selected="selectedGraph = $event"
			>
				<template
					v-slot="statsSlot"
				>
					<div
						v-if="statsSlot.statsData.maxBidHistoryData[statsSlot.statsData.over]"
					>
						<div
							class="header"
						>
							<span>{{ $t('request_list.updated') }}</span>
							<div class="header-date">
								<span>{{ formatDateMaxBid(statsSlot.statsData.maxBidHistoryData[statsSlot.statsData.over].ctime) }}</span>
								<br>
								<span>{{ formatTimeMaxBid(statsSlot.statsData.maxBidHistoryData[statsSlot.statsData.over].ctime) }}</span>
							</div>
						</div>
						<div class="max-bid">
							<span class="max-bid-label">{{ $t('campaign.max_bid') | uppercase }}</span>
							<div class="max-bid-diff">
								<span class="old-bid">{{ Math.round(statsSlot.statsData.maxBidHistoryData[statsSlot.statsData.over].old_bid) }}</span>
								<br>
								<span class="new-bid">{{ Math.round(statsSlot.statsData.maxBidHistoryData[statsSlot.statsData.over].new_bid) }}</span>
							</div>
						</div>
						<div class="bid-footer">
							<span class="bid-author">{{ $t('campaign.by_who') }} {{ statsSlot.statsData.maxBidHistoryData[statsSlot.statsData.over].created_by.first_name }}</span>
							<br>
							<span class="bid-author">{{ statsSlot.statsData.maxBidHistoryData[statsSlot.statsData.over].created_by.username }}</span>
						</div>
					</div>
				</template>
			</nice-stats>
		</main>
	</section>
</template>

<script>
import _ from 'underscore';
import * as moment from 'moment';

import { mapActions, mapGetters } from 'vuex';

import {
	requestBroadcastingReport,
	requestMaxBidHistory,
} from '@/api/campaign';

import NiceStats from '@/ui/nice-stats';
import NiceInput from '@/ui/nice-input';

import { formatCash } from '@/utilites';
import {
	getStatsGraphLabels,
	getStatsGraph,
	TIMESTAMP_KEY_FORMAT,
} from '@/ui/nice-stats/utils';


function formatDate(date) {
	return moment(date).format('DD MMM`YY');
}
function formatDateMaxBid(date) {
	return moment(date).format('YYYY.MM.DD');
}
function formatTimeMaxBid(date) {
	return moment(date).format('hh:mm:ss');
}


export default {
	name: 'TabStatus',


	components: {
		NiceStats,
		NiceInput,
	},


	filters: {
		parseFloat(value) {
			const number = parseFloat(value);
			if (isNaN(number)) {
				return value;
			}
			return parseFloat(number.toFixed(4));
		},
	},


	props: {
		instance: {
			type: Object,
			required: true,
		},

		campaignStats: {
			type: Object,
			required: true,
		},

		resource: {
			type: String,
			default: 'request',
			validator: resource => [
				'request',
				'campaign',
			].includes(resource),
		},
	},

	data() {
		return {
			formatDateMaxBid,
			formatTimeMaxBid,
			client: null,

			isBidEditModeOn: false,
			bidTempValue: this.instance.max_bid,
			bidValue: this.instance.max_bid,

			//
			// stubs
			//

			// needed for graph hints
			currency: '',

			graphData: [],
			labelData: [],

			adPlaysTotal: 0,
			spentTotal: 0,
			impressionsTotal: 0,

			adPlays: 0,
			spent: 0,
			impressions: 0,

			selectedGraph: 0,

			selectLabelKeys: ['sel_impressions', 'sel_spent', 'sel_ad_plays'],

			maxBidHistory: [],
		};
	},


	// async mounted() {
	//   this.$set(this, 'maxBidHistory', await requestMaxBidHistory(this.instance.id));
	// },


	computed: {
		...mapGetters('app', [
		  'isSSPViewer',
		  'isDSP',
		]),

		...mapGetters('extra', [
			'getPaymentModelByValue',
			'getBroadcastTypeByValue',
		]),


		adFlight() {
			let start = this.instance.start_date;
			let end = this.instance.end_date;
			let diff = moment(end).diff(moment(start), 'days');

			if (isNaN(diff)) return 0;

			return diff;
		},

		statLabels() {
			return this.selectLabelKeys.map(label => {return this.$t('campaign.'+label);});
		},

		timePeriod() {
			let start = this.instance.start_date;
			let end = this.instance.end_date;

			return `${ start ? formatDate(start) : '⋯' } — ${ end ? formatDate(end) : '⋯' }`;
		},

		currentPaymentModel() {
			return this.getPaymentModelByValue(this.instance.payment_model);
		},
	},

	methods: {
		...mapActions('devices', ['getStats']),
		...mapActions('campaign', {
			'updateCampaign': 'change',
		}),

		editBid() {
			this.isBidEditModeOn = true;
		},


		async applyBidChanges() {
			this.bidValue = this.bidTempValue;

			const data = {
				max_bid: this.bidValue
			};

			let campaign;
			try {
				campaign = await this.updateCampaign({
					id: this.instance.id,
					data: data,
				});
			} catch (error) {
				this.$log.error(error);
				this.cancelBidChanges();
				throw error;
			}

			this.$emit('update:instance', campaign);
			this.$emit('update');

			this.isBidEditModeOn = false;
		},


		cancelBidChanges() {
			this.bidTempValue = this.bidValue;
			this.isBidEditModeOn = false;
		},


		/**
		 * Update stats graphs
		 *
		 * TODO: copied from pages.index
		 *
		 * rangeType - { hourly, daily, weekly, monthly }
		 */
		async rangeUpdated({ rangeType, dateFrom, dateTill, platforms, agency, campaign }) {
			let labels = getStatsGraphLabels(rangeType, dateFrom, dateTill);
			let graphsData = await getStatsGraph(rangeType, dateFrom, dateTill, platforms, agency, campaign);

			let maxBidHistory = [];
			try {
				maxBidHistory = await requestMaxBidHistory(this.instance.id, rangeType);
			}
			catch(ex) {
				if (!ex.response || ex.response.status != 404) {
					this.$log.error(ex);
				}
			}

			let maxBidHistoryHash = {};
			for (let maxBid of maxBidHistory) {
				let key = moment(maxBid.ts).utcOffset(3).format(TIMESTAMP_KEY_FORMAT);

				// glue max_bid changes correctly
				let oldMaxBid = maxBid.old_bid;
				if (maxBidHistoryHash[key]) {
					oldMaxBid = maxBidHistoryHash[key].old_bid;
				}
				maxBidHistoryHash[key] = {
					...maxBid,
					old_bid: oldMaxBid,
				};
			}

			let graphs = [
				graphsData.impressions,
				graphsData.spentIncome,
				graphsData.adPlays,
				maxBidHistoryHash,
			];

			// fill sparse data with 0's
			let processedGraphs = [ [], [], [], [] ];

			for (let l of labels) {
				let key = l.format(TIMESTAMP_KEY_FORMAT);

				// impressions, spent / income, ad plays, max bid history
				for (let i = 0; i < graphs.length; i++) {
					let value = 0;
					if (_.has(graphs[i], key)) {
						value = graphs[i][key];
					}
					processedGraphs[i].push(value);
				}
			}

			let processedMaxBidHistory = processedGraphs.pop();
			this.$set(this, 'maxBidHistory', processedMaxBidHistory);

			// format labels
			let formatedLabels = [];
			for (let l of labels) {
				formatedLabels.push( l.format('ddd DD MMM') );
			}

			// set graphs
			this.$set(this, 'graphData', processedGraphs);
			this.$set(this, 'labelData', formatedLabels);

			let totalSpentInCurrency = formatCash(
				graphsData.meta.total_spent_income_in_currency,
				graphsData.meta.currency
			);

			// set totals
			this.$set(this, 'impressions', Math.floor(graphsData.meta.total_impressions));
			//this.$set(this, 'spent',  `${totalSpent} (${totalSpentInCurrency})`);
			this.$set(this, 'spent',  totalSpentInCurrency);
			this.$set(this, 'adPlays', graphsData.meta.total_ad_plays);

			// get stats for campaign w/o other filters
			// TODO: only current month for the external campaigns
			graphsData = await getStatsGraph(
				'monthly',
				this.instance.start_date ? this.instance.start_date : moment().startOf('month'),
				this.instance.end_date ? this.instance.end_date : moment().endOf('month'),
				platforms,  // platforms
				agency,  // agency
				this.instance.id,
			);
			this.$set(this, 'impressionsTotal', Math.floor(graphsData.meta.total_impressions));
			this.$set(this, 'spentTotal',  graphsData.meta.total_spent_income);
			this.$set(this, 'adPlaysTotal', graphsData.meta.total_ad_plays);
			this.$set(this, 'currency', graphsData.meta.currency);
		},


		/**
		 * Format hint inside the NiceStats graph
		 *
		 * Default is:
		 * <p class="content">
		 * 	<span class="label">{{ units || "Label" }}</span>
		 * 	<span class="value">{{ dataset.value[over] }}</span>
		 * </p>
		 *
		 * TODO: do it via some components render function!
		 * TODO: currency is from a state
		 */
		formatHint(graph) {

			let html = '';

			if (this.selectedGraph == 0 || this.selectedGraph == 2) {
				html = '<span class="value">' + graph.dataset.value[graph.over] + '</span>';
			}
			// this.selectedGraph == 1
			else {
				html = formatCash(
					graph.dataset.value[graph.over],
					this.currency
				);
			}

			// show max_bid change

			let maxBid = graph.maxBidHistoryData[graph.over];
			if (maxBid) {
				let max_bid_message = this.$t('campaign.max_bid_changed', {
					old: formatCash(maxBid.old_bid, this.instance.currency, 2),
					new: formatCash(maxBid.new_bid, this.instance.currency, 2),
				});
				html += '<span class="value max-bid-value">' + max_bid_message + '</span>';
			}

			return html;
		},


		async downloadBroadcastingReport() {
			let res = await requestBroadcastingReport(this.instance.id);
			const ancor = document.createElement('a');
			ancor.href = res.download_url;
			ancor.setAttribute('download', '');
			ancor.className = 'visuallyhidden';
			ancor.tabIndex = -1;
			document.body.appendChild(ancor);
			ancor.click();
			document.body.removeChild(ancor);
		},

		exportExcel() {
			const ancor = document.createElement('a');
			ancor.href = this.campaignStats.inventory.excel_url;
			ancor.setAttribute('download', '');
			ancor.className = 'visuallyhidden';
			ancor.tabIndex = -1;
			document.body.appendChild(ancor);
			ancor.click();
			document.body.removeChild(ancor);
		},
	},


	watch: {
		['instance.max_bid'](value, old) {
			this.bidValue = value;
			this.bidTempValue = value;
		},
	},
};
</script>

<style lang="sass" scoped>
.line
	margin-top: 20px
	margin-bottom: 54px

.details
	margin-bottom: -35px
	& > *
		margin-bottom: 35px

.detail
	display: flex
	flex-direction: column

.value
	display: flex
	align-items: center
	width: 100%
	line-height: 18px
	margin: (30px - 18px) / 2 0
	text-overflow: ellipsis

	&:not(.multiline)
		white-space: nowrap
		overflow: hidden

	& > *
		margin-left: .5em

	& > :first-child
		margin-left: 0

	& > .units,
	& > .shaded
		color: var(--text_2_color)


.detail-huge
	& > .label
		font-size: 11px

	& > .value
		font-weight: 300
		font-size: 16px


.inline-edit-field
	display: inline-block
	width: 125px

	& ::v-deep .ni_input--units
		color: var(--text_2_color)


.inline-edit-field_huge
	margin-top: 2px

	&::v-deep
		.ni_label--label
			margin-bottom: 4px
			line-height: 24px
			font-size: 11px

		.ni_input--input,
		.ni_input--units
			font-weight: 300
			font-size: 16px


.inline-edit-button
	max-height: 21px
	min-height: unset
	height: 21px
	box-sizing: border-box
	margin-left: 13px

.download-button
	max-height: 24px
	min-height: unset
	height: 24px
	box-sizing: border-box
	color: var(--text_2_color)
	margin-top: 14px

.input--description
	width: 217px
	height: 32px

	font-size: 10px
	line-height: 12px
	margin-bottom: -30px

	color: var(--text_2_color)
</style>
