<template>
	<main>

		<device-filter
			ref="device_filter"
			v-model="filterOptions"
			class="filter"
			title="Filter"
			:class="{ 'opened': filterShow }"
			:before-clear="askOnFilterChange"
			:before-apply="askOnFilterChange"

			:search-list-request.sync="searchListRequest"
			:search-query.sync="searchQuery"
			:errors.sync="errors"
			@update:areas="areas=$event"

			@initialized="onFilterInitialized"
			@apply-filter="onFilterChange"
			@clear-filter="onFilterChange"
		/>

		<nice-tabs>

			<section
				:data-ni_tab_name="$t('ssp.network_list_title')"
				data-ni_tab_active
			>
				<platforms-list
					:platforms="platforms"
					:agency="agency"
					:parentAgency="null"
					@update="updatePlatforms"

				/>
			</section>


			<section
				:data-ni_tab_name="$t('ssp.device_list_title')"
				data-ni_tab_active
			>
				<template v-if="deviceListTotalCount" >
					<table class="nice-table devices-table">
						<thead>
							<tr>
								<th>{{$t('ssp.id') | capitalize}}</th>
								<th>{{$t('ssp.name') | capitalize}}</th>
								<th>{{$t('ssp.address') | capitalize}}</th>
								<th>{{$t('ssp.resolution') | capitalize}}</th>
								<th>{{$t('inventory_modal.uptime') | capitalize}}</th>
								<th>{{$t('ssp.orientation') | capitalize}}</th>
								<th>{{$t('ssp.status') | capitalize}}</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="device in devices"
								:key="device.id"
							>
								<td>{{ device.external_id }}</td>
								<td class="col-name" ><nice-icon icon="monitor" /> {{ device.name }}</td>
								<td>{{ device.address }}</td>
								<td>{{ device.resolution }}</td>
								<td :style="{ color: $palette[uptime(device).color] }"> {{ uptime(device).value }} </td>
								<td>{{ device.orientation_display }}</td>
								<td>
									<nice-switch
										:value="device.is_deployed"
										:disabled="isDisabled(device.id)"
										:caption-on="$t('ssp.on')"
										:caption-off="$t('ssp.off')"
										@input="(value) => { deviceStatusToogle(device.id, value); }"
									/>
								</td>
							</tr>
						</tbody>
					</table>

					<table-paginator
						v-model="pagination"
						:total="deviceListTotalCount"
						:paginator-props="{ queryKey: 'p' }"
						class="network-paginator"
					/>
				</template>
			</section>


			<section
				:data-ni_tab_name="$t('ssp.map_title')"
			>

			<!-- enable-geocoder
			@searchListRequest="searchlistRequestHandler" -->
			<map-device
				:source-data="mapSource"
				:selected-areas-data="selectedAreasForMap"
				:search-query="searchQuery"
				class="layout_block-full_width"
				:without-control="true"
			/>

			</section>
		</nice-tabs>

		<!-- ONLY TO HAVE A GEOCODER PAGE WIDE -->
		<map-device
			:source-data="fakeMapSource"
			:selected-areas-data="selectedAreasForMap"
			enable-geocoder
			:search-query="searchQuery"
			class="hidden-map-for-geocoder"
			@searchListRequest="searchlistRequestHandler"
		/>

		<!-- AddPackage modal should be there -->
		<router-view />
	</main>
</template>

<script>
import _ from 'underscore';
import { mapState, mapGetters, mapActions } from 'vuex';

import apiSsp from '@/api/ssp/device';
import DeviceFilter from '@/components/device-filter';
import MapDevice from '@/components/map-device';
//import NiceCheckbox from '@/ui/nice-checkbox';
import NiceSwitch from '@/ui/nice-switch';
import NiceTabs from '@/ui/nice-tabs';
import TablePaginator from '@/components/table-paginator';
import PlatformsList from '@/components/users/platforms-list';
import { formatMetric, formatNumber, getDevicesFilter, objectToQuery } from '@/utilites';
import { request, getBaseApiUrl } from '@/api/request';


const DEBOUNCE_TIMEOUT = 1000;


export default {
	name: 'PageNetwork',


	components: {
		DeviceFilter,
		MapDevice,
		// NiceCheckbox,
		NiceSwitch,
		NiceTabs,
		TablePaginator,
		PlatformsList,
	},


	//
	// Props, data, computed - ie. variables
	//

	data() {
		return {
			selectAllState: false,
			filterOptions: {},
			filterShow: false,

			// save filters here
			filters: {},

			mapSource: {
				'type': 'FeatureCollection',
				'features': [],
			},
			fakeMapSource: {
				'type': 'FeatureCollection',
				'features': [],
			},

			// search address
			searchQuery: '',
			searchListRequest: [],

			// address areas
			areas: [],

			pagination: {
				page: 1,
				pageSize: 20,
			},

			// ???
			errors: {},
		};
	},


	computed: {
		...mapGetters('app', { appIsLoading: 'isLoading' }),
		...mapState('app', { appAgency: 'agency' }),
		...mapState({ agency: 'agency' }),
		...mapGetters('devices', {
			getDeviceChangeKey: 'loadingKeyItemChange',
			devicesIsLoading: 'isLoading',
			isDevicesFilterChanged: 'isFilterChanged',
		}),
		...mapState('devices', {
			devices: 'list',
			deviceListTotalCount: 'totalCount',
		}),

		selectedAreasForMap() {
			return this.areas.map(area => {
				return {
					...area,
					radiusInKm: area.radius,
				};
			});
		},

		listDevicesFilter() {
			return getDevicesFilter(this.filters);
		},

		mapDevicesFilter() {
			return objectToQuery(this.listDevicesFilter);
		},


		/**
		 * Platforms list
		 * SSP side only currently
		 *
		 * @return {object[]} list
		 */
		platforms() {
			if (!this.agency ||  !this.agency.ssp_networks) {
				return [];
			}

			return this.agency.ssp_networks.slice();
		},
	},


	//
	// Component Lifecycle: created, mounted, etc
	//

	created() {
		// delay so we don't spam MapBox & our stats endpoints
		this.debouncedUpdateMap = _.debounce(this.updateMap, DEBOUNCE_TIMEOUT);
	},

	mounted() {
		this.onFilterInitialized();
		this.updateMap();
	},


	//
	// Watches
	//

	watch: {
		appAgency(value, oldValue) {
			if (value && value !== oldValue) {
				let force = true;
				this.requestDeviceList({
					filters: this.listDevicesFilter,
					force: force,
				});
			}
		},

		/**
		 * Update request/campaign stats on filter change
		 *
		 * debounced
		 */
		mapDevicesFilter: {
			deep: true,
			handler() {
				this.debouncedUpdateMap();
			},
		},

		pagination: {
			deep: true,
			handler(value, old) {
				if (_.isEqual(value, old)) {
					return;
				}

				let force = true;
				this.requestDeviceList({
					filters: this.listDevicesFilter,
					...value,
					force: force,
				});
			},
		},
	},


	//
	// Other methods...
	//

	methods: {
		...mapActions('devices', {
			requestDeviceList: 'requestList',
			deviceChange: 'change',
		}),

		formatMetric,
		formatNumber,

		toggleFilter() {
			this.filterShow = !this.filterShow;
		},

		deviceStatusToogle(id, value) {
			this.deviceChange({
				id: id,
				data: {
					is_deployed: value
				}
			});
		},

		/**
		 * Indicate change device now or not
		 *
		 * @param {number} device id
		 *
		 * @returns {boolean}
		 */
		isDisabled(id) {
			return this.$store.getters['app/inProgress'][this.getDeviceChangeKey(id)];
		},

		/**
		 * Filter initialization handler
		 */
		onFilterInitialized(filters) {

			if (typeof filters == 'undefined') {
				filters = {
					platform: 'all'
				};
				this.$set(this, 'filters', filters);
			}

			// HACK:
			let initDevices = () => {
				if (!this.appAgency) {  // || !this.$refs.device_filter) {
					setTimeout(initDevices, 1000);
					return;
				}

				let force = true;
				this.requestDeviceList({
					filters: this.listDevicesFilter,
					force,
				});
			};

			initDevices();
		},

		/**
		 * Filter apply and clear button handler
		 *
		 * TODO: check if filter really changed
		 */
		onFilterChange(filters) {
			// TODO: we have filterOptions as a v-model!!!
			// HACK: request devices from all the platforms
			filters.platform = 'all';
			this.$set(this, 'filters', filters);

			this.requestDeviceList({
				filters: this.listDevicesFilter,
			});
		},

		/**
		 * TODO: remove?
		 */
		async askOnFilterChange(filters) {
			return true;
		},

		//
		// For map
		//

		updateMap() {
			this.setMapSource();
		},

		async setMapSource() {
			const apiPath = apiSsp.getPrefix().slice(0, -1);
			const url = `${getBaseApiUrl()}${apiPath}.geojson${this.mapDevicesFilter}`;

			const result = await request(url);

			this.$set(this, 'mapSource', result);
		},

		searchlistRequestHandler(e) {
			this.searchListRequest = e;
		},

		updatePlatforms(list) {
			// TODO: ???
		},

		uptime(device) {
			switch (device.uptime) {
			case 'good':
				return {
					value: this.$t('inventory_modal.good_uptime_value'),
					color: 'green',
				};
			case 'average':
				return {
					value: this.$t('inventory_modal.average_uptime_value'),
					color: 'purple',
				};
			case 'bad':
				return {
					value: this.$t('inventory_modal.bad_uptime_value'),
					color: 'red',
				};
			default:
				return '-';
			}
		},
	},
};
</script>


<style lang="sass" scoped >
main
	margin-bottom: 110px

$margin-left: 90px
.filter-n-chart-container
	position: relative
	display: flex
	flex-direction: row

	.filter,
	.chart
		flex: 1
		flex-basis: 50%

	.filter
		display: none
		margin: 20px 0px
		margin-left: $margin-left

		&.opened
			display: flex
			min-width: calc(50% - #{$margin-left})

			+ .chart
				margin-left: 20px

	.chart
		margin: 20px 45px
		margin-left: $margin-left

.toggle-filter
	$height: 30px

	position: absolute
	top: 50%
	left: 0
	transform: rotate(-90deg)

	display: flex
	flex-direction: row
	justify-content: center
	align-items: center
	margin: 0
	height: $height
	padding: 0 22px 0 20px

	background: $nice_color-blue
	border-radius: 15px
	border: none
	color: white
	cursor: pointer
	text-transform: uppercase
	outline: none

	// &:focus
	// 	box-shadow: 0 0 0 1px $nice_color-darkgray

	&.opened
		background: $nice_color-activebutton
		color: $nice_color-blue

		// &:focus
		// 	box-shadow: 0 0 0 1px $nice_color-blue

	.nice-icon
		margin-right: 8px

.nice-checkbox,
.select-all .nice-icon
	display: inline-block
	vertical-align: bottom
	margin-bottom: 0px
	margin-right: 6px


.devices-table
	width: 100%
	margin-top: 40px

	td
		.nice-icon
			display: inline-block
			vertical-align: middle
			margin-right: 6px


.map
	height: 500px
	padding-bottom: $default-indent_y


.network-paginator
	width: auto


.select-all
	+button__clear
	margin-right: 6px

	.nice-icon
		margin-right: 0

	.state-checked.page
		padding-top: 0

circle
	fill: red !important

.select-button
	@extend %button__text

	display: block
	padding: 5px 5px
	font-size: 11px

.hidden-map-for-geocoder
	visibility: hidden
	width: 0px
	height: 0px

::v-deep
	.mapboxgl-ctrl-geocoder.mapboxgl-ctrl
		visibility: hidden
		display: none

	.select_areas
		width: 100%
</style>
