<template>
	<section class="invitation-modal modal">

		<div class="logo-wrap">
			<logo class="logo" />
			<h1 class="title">
				{{ $uioverrides.name }}
			</h1>
		</div>
		<h2 class="subtitle">
			{{ $t('registration.registration') }}
		</h2>


		<RegistrationInvitationForm
			v-if="!created"
			:do-create-agency="true"
			@form-submit="registerAccount"
		/>

		<div
			v-else
			id="message"
		>
			<p v-html="$t('invitation.confirm_email')"></p>
			<nice-button-2
				@click="$router.push({ name: 'login' })"
			>
				{{ $t('two_factor_auth.enter') }}
			</nice-button-2>
		</div>
	</section>
</template>


<script>
import { mapMutations } from 'vuex';

import { registerAgencyUser } from '@/api/agency';

import Logo from '@/ui/logo.vue';
import RegistrationInvitationForm from '@/components/registration-invitation-form';


export default {
	name: 'PageRegistration',


	components: {
		Logo,
		RegistrationInvitationForm,
	},

	data() {
		return {
			created: false
		};
	},

	mounted() {
		if (!this.$isWhitelabel) {
			this.$router.push('/');
		}
	},


	methods: {
		...mapMutations({
			setAgency: 'app/setAgency',
		}),

		// TODO: copy of PageInvitation.createAccount()
		async registerAccount(user, formComponent) {
			user.username = user.email;

			try {
				let res = await registerAgencyUser(user);
				if (res.status === 201) {
					this.created = true;
				}
			}
			catch(error) {
				let errors = {};

				if (error && error.response && error.response.data) {
					for (var k in error.response.data) {
						if (!formComponent.$refs[k]) {
							continue;
						}

						let msg = '';
						// translations are on the backend
						for (let m of error.response.data[k]) {
							msg += m + '\n';
						}

						errors[k] = msg;
					}
				}

				formComponent.$set(formComponent, 'errors', errors);
				formComponent.goToTheFirstError();

				if (Object.keys(errors).length == 0) {
					alert(this.$t('invitation.failed_to_create_account'));
				}
			}
		},
	},  // END: methods
};
</script>


<style lang="sass" scoped>
.modal.invitation-modal
	display: flex
	flex-direction: column
	margin: 0 auto
	padding: 50px 0
	box-sizing: border-box
	flex-grow: 1
	min-height: 200px
	max-width: 392px
	gap: 42px

	.logo-wrap
		.logo
			display: block
			margin: 0 auto

		.title
			margin: 16px 0 0 0
			font-size: 30px
			line-height: 140%
			font-weight: 400
			text-align: center

	.subtitle
		margin: 0
		font-size: 20px
		line-height: 140%
		font-weight: 400
		text-align: center

#message
	text-align: center
	font-size: 15px
</style>
