<template>
	<main class="page-auth" >
		<section class="auth-container">
			<form
				v-if="!token && !twoFactorAuth"
				class="auth-form"
				@submit.prevent="loginRequest"
			>
			  <logo class="logo" />
				<h1>{{ $uioverrides.name }}</h1>
				<h2>{{ $t('common.enter') }}</h2>

				<nice-input
					id="email-auth-field"
					v-model="email"
					type="text"
					:placeholder="$t('common.type_email')"
					label="Email"

					autocomplete="email"
				/>
				<nice-input
					id="password-auth-field"
					v-model="password"
					type="password"
					:placeholder="$t('common.type_password')"
					label="Password"

					autocomplete="current-password password"
				/>
				<nice-label
					v-if="wrongEmail"
					class="label-detail"
					:label="wrongEmail | capitalize"
					:error="true"
				/>
				<nice-label
					v-else-if="wrongCredentials"
					class="label-detail"
					:label="$t('notifications.wrong_credentials') | capitalize"
					:error="true"
				/>

				<div class="help-container">
					<button type="button"
						class="reset-password-button help-text"
						@click="$router.push({name: 'reset-password'})"
					>
						{{ $t('reset_password.reset_password') }}
					</button>
					<div 
						v-if="$uioverrides.help_phone || $uioverrides.help_email"
						class="help-block" 
						@click="showHelpModal"
					>
						<span class="help-text">{{ $t('common.help_is_needed') }}</span>
						<nice-icon-2 class="hand" icon="hand" />
					</div>
				</div>

				<div class="submit-container">
					<nice-button-2
						type="submit"
						icon="arrow-14"
						:icon-state="-90"
						icon-placement="end"
						icon-painted
						filled
						:disabled="!email || !password"
						class="submit_button"
					>{{ $t('two_factor_auth.enter') | capitalize }}</nice-button-2>
				</div>
				<div class="register-container">
					<nice-button-2
						v-if="$isWhitelabel"
						class="register-button"
						@click="$router.push({name: 'registration'})"
					>
						{{ $t('registration.register') | capitalize }}
					</nice-button-2>
				</div>
			</form>

			<div v-if="twoFactorAuth" class="two-factor">
				<nice-input
					id="two-factor-auth-field"
					v-model="twoFactorCode"
					type="text"
					:placeholder="$t('two_factor_auth.type_code') | capitalize"
					:label="$t('two_factor_auth.code') | capitalize"
					@keyup.enter="check2fa"
				/>
				<nice-label
					v-if="wrongCode"
					class="label-detail"
					:label="$t('notifications.wrong_code') | capitalize"
					:error="true"
				/>
				<div class="submit-container">
					<nice-button-2
						v-if="twoFactorAuth"
						palette="gray"
						icon="arrow-14"
						icon-placement="start"
						icon-state="90"
						class="submit_button"
						@click="twoFactorAuth = false"
					>
						{{ $t('campaign.back_btn_label') | capitalize }}
					</nice-button-2>
					<nice-button-2
						type="submit"
						icon="arrow-14"
						:icon-state="-90"
						icon-placement="end"
						icon-painted
						class="submit_button"
						:disabled="!twoFactorCode"
						@click="check2fa"
					>{{ $t('two_factor_auth.next') | capitalize }}</nice-button-2>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

import { requestAgencies } from '@/api/agency';

import Logo from '@/ui/logo.vue';
import NiceInput from '@/ui/nice-input.vue';


export default {
	name: 'PageLogin',

	components: {
		NiceInput,
		Logo,
	},


	data() {
		return {
			email: '',
			password: '',
			agencies: [],
			wrongEmail: '',
			wrongCredentials: false,
			wrongCode: false,

			twoFactorAuth: false,
			twoFactorCode: '',
			token2fa: '',
		};
	},


	computed: {
		...mapState('profile', ['token', 'refresh']),
		...mapGetters('profile', ['profile']),
		...mapGetters('agency', ['agency']),
	},


	methods: {
		...mapActions('profile', [
			'login',
			'login2fa',
			'getProfile',
		]),
		...mapMutations({
			setAgency: 'app/setAgency',
		}),
		...mapMutations('modals', ['setShowHelpModal']),

		async check2fa() {
			if (!this.twoFactorCode) {
				return;
			}

			try {
				await this.login2fa({
					token: this.token2fa,
					code: this.twoFactorCode,
				});

				await this.checkAgency();
			}
			catch (err) {
				this.wrongCode = true;
				console.log(err.response);
			}
		},

		async checkAgency() {
			const hasNextRoute = this.$route.query && this.$route.query.next;
			let agencies = await requestAgencies();

			// go to select agency popup
			if (agencies.length > 1) {
				this.$set(this, 'agencies', agencies);

				this.$router.push({
					name: 'choose-agency',
					query: {
						next: hasNextRoute ? this.$route.query.next : '',
					},
				});

				return;
			}

			// go to next/index page
			this.setAgency(agencies[0].id);

			await this.getProfile();

			this.$router.push(hasNextRoute ? { path: this.$route.query.next } : { name: 'index' });
		},

		async loginRequest() {
			try {
				this.wrongEmail = '';
				this.wrongCredentials = false;

				let response = await this.login({
					email: this.email,
					password: this.password,
				});

				if (response.code == '2fa') {
					this.twoFactorAuth = true;
					this.$set(this, 'token2fa', response.token2fa);
					return;
				}

				await this.checkAgency();
			}
			catch(err) {
				if (err.response && err.response.data) {
					for (let e in err.response.data) {
						if (e === 'email') {
							this.wrongEmail = err.response.data['email'];
							break;
						}
						this.wrongCredentials = true;
						// take the first key/element
						break;
					}
				}
			}
		},

		showHelpModal() {
			this.setShowHelpModal(true);
		},
	}
};
</script>

<style lang="sass" scoped>
main.page-auth
	display: flex
	align-items: center
	justify-content: center

	min-height: 100vh

	h1
		font-weight: 400
		font-size: 30px
		line-height: 42px
		text-align: center
		margin: 16px auto 0

	h2
		font-weight: 400
		font-size: 20px
		line-height: 28px
		text-align: center
		margin: 42px auto 12px

	.logo
		width: 105px
		height: 105px
		margin: 0 auto

.auth-container
	margin: 0 auto
	width: 100%
	max-width: 392px
	box-sizing: border-box

.auth-form
	display: flex
	flex-direction: column
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: stretch

	::v-deep .nice-label
		line-height: 12px
		height: auto 
		margin: 0 0 4px 0

	> *
		margin-top: 30px

.help-container
	position: relative
	margin-top: 42px
	height: 18px
	line-height: 18px
	color: var(--text_2_color)
	font-size: 11px
	font-weight: 700
	text-transform: uppercase
	
	.hand
		position: absolute
		left: 0
		width: 18px
	
	.help-text
		display: block
		float: left
		padding-left: 28px
		color: var(--text_2_color)
		font-size: 11px
		line-height: 18px
		font-weight: 700
		text-transform: uppercase

	.help-block
		cursor: pointer
	
	.reset-password-button
		float: right
		padding: 0
		line-height: 18px
		text-align: right
		background: none
		border: none
		cursor: pointer
		
.submit-container
	margin-top: 42px
	display: flex
	flex-direction: row
	justify-content: space-between

	.submit_button
		align-self: flex-end
		width: 100%

	.nice-button
		min-height: 35px

.register-container
	margin-top: 25px
	text-align: center
	font-size: 11px;
	font-weight: 700;
	text-transform: uppercase;

	.register-button
		align-self: flex-start

	.nice-button
		min-height: auto

.label-detail
	font-size: 90%

.two-factor
	display: flex
	flex-direction: column
	justify-content: space-between

</style>
