export default {
	invitation: {
		en: 'invitation',
		ge: 'მოწვევა',
		de: 'Einladung',
		ru: 'приглашение',
		cn: '邀请'
	},
	invite_user: {
		en: 'invite user',
		ge: 'მომხმარებლის მოწვევა',
		de: 'Benutzer einladen',
		ru: 'пригласить пользователя',
		cn: '邀请用户'
	},
	email_to_send_invitation_to: {
		en: 'email to send invitation to',
		ge: 'ელფოსტა მოწვევის გასაგზავნად',
		de: 'E-Mail für Einladung senden',
		ru: 'email для отправки приглашения',
		cn: '要发送邀请的电子邮箱'
	},
	send_invitation: {
		en: 'send invitation',
		ge: 'მოწვევის გაგზავნა',
		de: 'Einladung senden',
		ru: 'отправить приглашение',
		cn: '发送邀请'
	},
	sent: {
		en: 'Invitation is sent to the email',
		ge: 'მოწვევა იგზავნება მეილზე',
		de: 'Einladung wurde per E-Mail gesendet',
		ru: 'Приглашение отправлено на email',
		cn: '邀请已发送至电子邮箱'
	},
	failed: {
		en: 'Failed to send the invitation',
		ge: 'მოწვევის გაგზავნა ვერ მოხერხდა',
		de: 'Einladung konnte nicht gesendet werden',
		ru: 'Не удалось отправить приглашение',
		cn: '无法发送邀请'
	},
	email_failed: {
		en: 'Failed to send an email',
		ge: 'ელფოსტის გაგზავნა ვერ მოხერხდა',
		de: 'E-Mail konnte nicht gesendet werden',
		ru: 'Не удалось отправить email',
		cn: '无法发送电子邮件'
	},
	account_creation: {
		en: 'create account',
		ge: 'ანგარიშის შექმნა',
		de: 'Konto erstellen',
		ru: 'создание аккаунта',
		cn: '创建账号'
	},
	create_account: {
		en: 'create account',
		ge: 'ანგარიშის შექმნა',
		de: 'Konto erstellen',
		ru: 'создать аккаунт',
		cn: '创建账号'
	},
	email_cant_be_empty: {
		en: 'Email can\'t be empty',
		ge: 'ელფოსტის ველი არ შეიძლება იყოს ცარიელი',
		de: 'E-Mail darf nicht leer sein',
		ru: 'Email не может быть пустым',
		cn: '电子邮箱不能为空'
	},
	phone_cant_be_empty: {
		en: 'Phone can\'t be empty',
		ge: 'ტელეფონის ველი არ შეიძლება იყოს ცარიელი',
		de: 'Telefonnummer darf nicht leer sein',
		ru: 'Телефон не может быть пустым',
		cn: 'Phone can\'t be empty'  // TODO: translation needed
	},
	agency_name_cant_be_empty: {
		en: 'Organization name can\'t be empty',
		ge: 'ორგანიზაციის სახელის ველი არ შეიძლება იყოს ცარიელი',
		de: 'Organisationsname darf nicht leer sein',
		ru: 'Название организации (или ФИО для физлица) не может быть пустым',
		cn: 'Organization name can\'t be empty'  // TODO: translation needed
	},
	first_name_cant_be_empty: {
		en: 'First name can\'t be empty',
		ge: 'სახელის ველი არ შეიძლება იყოს ცარიელი',
		de: 'Vorname darf nicht leer sein',
		ru: 'Имя не может быть пустым',
		cn: 'First name can\'t be empty'  // TODO: translation needed
	},
	last_name_cant_be_empty: {
		en: 'Last name can\'t be empty',
		ge: 'გვარის ველი არ შეიძლება იყოს ცარიელი',
		de: 'Nachname darf nicht leer sein',
		ru: 'Фамилия не может быть пустым',
		cn: 'Last name can\'t be empty'  // TODO: translation needed
	},
	password_cant_be_empty: {
		en: 'Password can\'t be empty',
		ge: 'პაროლის ველი არ შეიძლება იყოს ცარიელი',
		de: 'Passwort darf nicht leer sein',
		ru: 'Пароль не может быть пустым',
		cn: '密码不能为空'
	},
	passwords_mismatch: {
		en: 'passwords mismatch',
		ge: 'პაროლების შეუსაბამობა',
		de: 'Passwörter stimmen nicht überein',
		ru: 'Пароли не совпадают',
		cn: '密码不匹配'
	},
	failed_to_create_account: {
		en: 'Failed to create account',
		ge: 'ანგარიშის შექმნა ვერ მოხერხდა',
		de: 'Konto konnte nicht erstellt werden',
		ru: 'Не удалось создать аккаунт',
		cn: '无法创建账号'
	},
	confirm_invitation_deletion: {
		en: 'Are you sure you want to delete the invitation?',
		ge: 'დარწმუნებული ხართ, რომ გსურთ მოწვევის წაშლა?',
		de: 'Sind Sie sicher, dass Sie die Einladung löschen möchten?',
		ru: 'Вы уверены, что хотите удалить приглашение?',
		cn: 'Are you sure you want to delete invitation?'  // TODO: translation needed
	},
	failed_to_delete_invitation: {
		en: 'failed to delete the invitation',
		ge: 'მოწვევის წაშლა ვერ მოხერხდა',
		de: 'Einladung konnte nicht gelöscht werden',
		ru: 'не удалось удалить приглашение',
		cn: 'failed to delete the invitation'  // TODO: translation needed
	},
	first_name: {
		en: 'First name',
		ge: 'სახელი',
		de: 'Vorname',
		ru: 'Имя',
		cn: 'First name'  // TODO: translation needed
	},
	last_name: {
		en: 'Last name',
		ge: 'გვარი',
		de: 'Nachname',
		ru: 'Фамилия',
		cn: 'Last name'  // TODO: translation needed
	},
	password: {
		en: 'Password',
		ge: 'პაროლი',
		de: 'Passwort',
		ru: 'Пароль',
		cn: 'Password'  // TODO: translation needed
	},
	repeat_password: {
		en: 'Repeat password',
		ge: 'გაიმეორეთ პაროლი',
		de: 'Passwort wiederholen',
		ru: 'Повторите пароль',
		cn: 'Repeat password'  // TODO: translation needed
	},
	organization_name: {
		en: 'Organization name',
		ge: 'ორგანიზაციის სახელი',
		de: 'Organisationsname',
		ru: 'Название организации (или ФИО для физлица)',
		cn: 'Organization name'  // TODO: translation needed
	},
	email: {
		en: 'Email',
		ge: 'ელფოსტა',
		de: 'E-Mail',
		ru: 'Email',
		cn: 'Email'  // TODO: translation needed
	},
	phone_number: {
		en: 'Phone number',
		ge: 'ტელეფონის ნომერი',
		de: 'Telefonnummer',
		ru: 'Номер телефона',
		cn: 'Phone number'  // TODO: translation needed
	},
	personal_invitation_to_platform1: {
		en: 'Hello, it is a personal invitation to the',
		ge: 'გამარჯობა, ეს არის პირადი მოწვევა',
		de: 'Hallo, dies ist eine persönliche Einladung zur',
		ru: 'Привет, это персональное приглашение в',
		cn: 'Hello, it is a personal invitation to the'  // TODO: translation needed
	},
	personal_invitation_to_platform2: {
		en: '{platform_name} platform',
		ge: '{platform_name} პლატფორმა',
		de: '{platform_name}-Plattform',
		ru: 'платформу {platform_name}',
		cn: '{platform_name} platform'  // TODO: translation needed
	},
	// TODO: translation needed
	tos_caption: {
		en: 'Нажимая кнопку Продолжить, я подтверждаю свое согласие с Пользовательским соглашением в отношении использования сайта, а также принимаю условия Пользовательского соглашения на использование программного обеспечения "AddReality sphere" и даю свое согласие ООО "Эддреалити" на обработку моих персональных данных в соответствии с законодательством РФ, на условиях и для целей, определенных Политикой конфиденциальности.',
		ge: 'Нажимая кнопку Продолжить, я подтверждаю свое согласие с Пользовательским соглашением в отношении использования сайта, а также принимаю условия Пользовательского соглашения на использование программного обеспечения "AddReality sphere" и даю свое согласие ООО "Эддреалити" на обработку моих персональных данных в соответствии с законодательством РФ, на условиях и для целей, определенных Политикой конфиденциальности.',
		de: 'Indem ich auf die Schaltfläche Weiter klicke, bestätige ich, dass ich den Nutzungsbedingungen der Website zustimme, sowie den Bedingungen der Nutzungsvereinbarung zur Nutzung der Software "AddReality sphere" und ich gebe mein Einverständnis zur Verarbeitung meiner personenbezogenen Daten durch LLC "AddReality" gemäß den Gesetzen der Russischen Föderation, unter den Bedingungen und für die in der Datenschutzrichtlinie festgelegten Zwecke.',
		ru: 'Нажимая кнопку Продолжить, я подтверждаю свое согласие с Пользовательским соглашением в отношении использования сайта, а также принимаю условия Пользовательского соглашения на использование программного обеспечения "AddReality sphere" и даю свое согласие ООО "Эддреалити" на обработку моих персональных данных в соответствии с законодательством РФ, на условиях и для целей, определенных Политикой конфиденциальности.',
		cn: 'Нажимая кнопку Продолжить, я подтверждаю свое согласие с Пользовательским соглашением в отношении использования сайта, а также принимаю условия Пользовательского соглашения на использование программного обеспечения "AddReality sphere" и даю свое согласие ООО "Эддреалити" на обработку моих персональных данных в соответствии с законодательством РФ, на условиях и для целей, определенных Политикой конфиденциальности.'
	},
	// TODO: translation needed
	confirm_email: {
		en: 'Account created successfully.<br /> You need to create an account using the link in the letter that will be sent by email',
		ge: 'ანგარიში წარმატებით შეიქმნა.<br /> თქვენ უნდა შექმნათ ანგარიში მეილში მოცემული ბმულის გამოყენებით, რომელიც გამოგეგზავნებათ მეილზე',
		de: 'Konto erfolgreich erstellt.<br /> Sie müssen das Konto über den Link in der E-Mail bestätigen, die an die E-Mail-Adresse gesendet wird',
		ru: 'Аккаунт успешно создан.<br /> Необходимо подтвердить учетную запись по ссылке в письме, которое придет на электронную почту',
		cn: 'Account created successfully.<br /> You need to create an account using the link in the letter that will be sent by email.'
	},
	// TODO: translation needed
	tos_not_accepted: {
		en: 'TOS not accepted',
		ge: 'TOS არ არის მიღებული',
		de: 'Nutzungsbedingungen nicht akzeptiert',
		ru: 'Пользовательское соглашение не приняты',
		cn: 'TOS not accepted'
	},

	// TODO: move into user?
	role: {
		en: 'Role',
		ge: 'როლი',
		de: 'Rolle',
		ru: 'Роль',
		cn: 'Role'  // TODO: translation needed
	},
	dsp: {
		en: 'DSP Administrator',
		ge: 'DSP Administrator',
		de: 'DSP-Administrator',
		ru: 'DSP Администратор',
		cn: 'DSP Administrator'  // TODO: translation needed
	},
	// copy of dsp
	dsp_admin: {
		en: 'DSP Administrator',
		ge: 'DSP Administrator',
		de: 'DSP-Administrator',
		ru: 'DSP Администратор',
		cn: 'DSP Administrator'  // TODO: translation needed
	},
	ssp_admin: {
		en: 'SSP Administrator',
		ge: 'SSP Administrator',
		de: 'SSP-Administrator',
		ru: 'SSP Администратор',
		cn: 'SSP Administrator'  // TODO: translation needed
	},
	ssp_approver: {
		en: 'SSP Moderator',
		ge: 'SSP Moderator',
		de: 'SSP-Moderator',
		ru: 'SSP Модератор',
		cn: 'SSP Moderator'  // TODO: translation needed
	},
	// TODO: some stinking code here
	ssp_approve: {
		en: 'SSP Moderator',
		ge: 'SSP Moderator',
		de: 'SSP-Moderator',
		ru: 'SSP Модератор',
		cn: 'SSP Moderator'  // TODO: translation needed
	},
	ssp_viewer: {
		en: 'SSP Viewer',
		ge: 'SSP Viewer',
		de: 'SSP-Zuschauer',
		ru: 'SSP Наблюдатель',
		cn: 'SSP Viewer'  // TODO: translation needed
	},
};
