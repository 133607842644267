import store from '@/store';

import { request } from './request';
import baseRest from './base/rest';
import { getPrefix as _getPrefix } from './agency';


/**
 * Campaign endpoint prefix
 */
const getPrefix = () => `${_getPrefix()}${store.state.app.side}/campaign/`;


/**
 * Campaign History
 *
 * @param {number} campaign_id
 * @param {object} axios config
 */
export async function requestHistory(campaign_id, config) {
	return await request(`${_getPrefix()}${store.state.app.side}/campaign/${campaign_id}/history/`, null, 'get', config);
}


/**
 * Campaign Max Bid History
 *
 * @param {number} campaign_id
 * @param {string} rangeType
 * @param {object} axios config
 */
export async function requestMaxBidHistory(campaign_id, rangeType='daily', config) {
	return await request(`${_getPrefix()}${store.state.app.side}/max-bid-history/${campaign_id}/?type=${rangeType}`, null, 'get', config);
}


/**
 * Request Broadcasting Report
 *
 * @param {object} campaign_id
 * @param {object} axios config
 */
export async function requestBroadcastingReport(campaign_id, config) {
	return await request(`${getPrefix()}${campaign_id}/download-broadcasting-report/`, null, 'get', config);
}


/**
 * Change campaign audit
 *
 * TODO: copy from `api/dsp/request.js` and `api/ssp/request.js`
 *
 * @param {int} requestOrCampaignId - request or campaign ID
 * @param {int} sspAgencyId - auditing SSP agency ID
 * @param {int} status - new status. see `constants/audit-status-codes.js`
 * @param {object} config - axios request config
 */
export async function changeAudit(requestOrCampaignId, sspAgencyId, status, config=null) {
	return await request(
		`${getPrefix()}${requestOrCampaignId}/change-audit/`,
		{
			id: requestOrCampaignId,
			ssp_agency_id: sspAgencyId,
			status: status,
		},
		'post'
	);
}


export default {
	getPrefix,

	'list': baseRest(getPrefix).list,
	'create': baseRest(getPrefix).create,
	'retrieve': baseRest(getPrefix).retrieve,
	'patch': baseRest(getPrefix).patch,

	requestMaxBidHistory,
	requestBroadcastingReport,
	requestHistory,
	changeAudit,
};
